import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "siendo-más-lista-que-los-contratos",
      "style": {
        "position": "relative"
      }
    }}>{`Siendo más lista que los contratos`}<a parentName="h1" {...{
        "href": "#siendo-m%C3%A1s-lista-que-los-contratos",
        "aria-label": "siendo más lista que los contratos permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Realizaremos un curso de contratos inteligentes creado por OpenZeppelin, el equipo responsable de escribir las bibliotecas de contratos inteligentes más utilizadas y confiables, y también una de las firmas de auditoría y seguridad más respetadas en web3.`}</p>
    <p>{`Habrá cuatro lecciones y cada una durará aproximadamente 2 horas, ya que la intención es tener mucho espacio para exploraciones compartidas, preguntas y un enfoque tranquilo para aprender sobre un mundo nuevo.`}</p>
    <p>{`Este gremio está destinado principalmente a personas que nunca antes han visto un "smart contract" (contrato inteligente). No te convertirás en un súper programador, pero aprenderás cómo funcionan realmente redes como Ethereum y descubrirás muchos de los aspectos más sutiles que a menudo se pasan por alto en los artículos de los medios simplistas sobre el "futuro del dinero" o cualquier otro título que llame la atención y que funcione mejor en la pruebas A/B de ese día.`}</p>
    <p>{`Como siempre en Kernel, el contenido es secundario a la conversación que mantenemos en torno a él. Ya existen numerosas respuestas completas para cada uno de los acertijos de Ethernaut que puedes encontrar simplemente buscándolas en Google. Sin embargo, lo que no encontrarás en ningún otro lugar es la profundidad de la reflexión y la cuidadosa comprensión que esperamos cultivar juntos en este gremio.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      